import LogoSection from "../../components/Logo";

function Bet() {
  return (
    <div className="flex flex-col pt-11 pr-4 pb-4 pl-4 md:p-12 items-start gap-8 overflow-auto">
      <div className="md:hidden self-center"><LogoSection logowidth={103} logoheight={60} /></div>
      <p className="text-white text-2xl">Current Bets</p>
      <div className="flex flex-col items-center gap-3 md:min-h-full md:w-full min-h-screen">
        <div className="flex bg-white bg-opacity-[15%] w-full rounded-lg">
          <div className="md:w-1/6 min-w-[200px] text-white text-center py-2 px-3 flex justify-center">GAME</div>
          <div className="md:w-1/6 min-w-[200px] text-white text-center py-2 px-3 flex justify-center">PLAYER</div>
          <div className="md:w-1/6 min-w-[200px] text-white text-center py-2 px-3 flex justify-center">TIME</div>
          <div className="md:w-1/6 min-w-[200px] text-white text-center py-2 px-3 flex justify-center">WAGER</div>
          <div className="md:w-1/6 min-w-[200px] text-white text-center py-2 px-3 flex justify-center">MULTIPLAYER</div>
          <div className="md:w-1/6 min-w-[200px] text-white text-center py-2 px-3 flex justify-center">PAYOUT</div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <div className="flex bg-white bg-opacity-5 w-full rounded">
            <div className="py-2 px-3  text-white flex items-center w-1/6 min-w-[200px] justify-center">
              <img src="./images/fortnite.png" alt="fortnite" className="w-6 h-6 object-cover" />
              <p className="text-white text-sm ml-2">Fortnite</p>
            </div>
            <div className="py-2 px-3  text-white flex items-center w-1/6 min-w-[200px] justify-center">
              <img src="./images/playerA.png" alt="playerA" className="w-6 h-6 object-cover" />
              <p className="text-white text-sm ml-2">wooka</p>
            </div>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">3S</p>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">$800.00</p>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">0.32x</p>
            <p className="py-2 px-3  text-green-500 text-center w-1/6 min-w-[200px] self-center">$256.00</p>
          </div>
          <div className="flex bg-white bg-opacity-5 w-full rounded">
            <div className="py-2 px-3  text-white flex items-center w-1/6 min-w-[200px] justify-center">
              <img src="./images/fortnite.png" alt="fortnite" className="w-6 h-6 object-cover" />
              <p className="text-white text-sm ml-2">Fortnite</p>
            </div>
            <div className="py-2 px-3  text-white flex items-center w-1/6 min-w-[200px] justify-center">
              <img src="./images/playerA.png" alt="playerA" className="w-6 h-6 object-cover" />
              <p className="text-white text-sm ml-2">wooka</p>
            </div>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">3S</p>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">$800.00</p>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">0.32x</p>
            <p className="py-2 px-3  text-green-500 text-center w-1/6 min-w-[200px] self-center">$256.00</p>
          </div>
          <div className="flex bg-white bg-opacity-5 w-full rounded">
            <div className="py-2 px-3  text-white flex items-center w-1/6 min-w-[200px] justify-center">
              <img src="./images/fortnite.png" alt="fortnite" className="w-6 h-6 object-cover" />
              <p className="text-white text-sm ml-2">Fortnite</p>
            </div>
            <div className="py-2 px-3  text-white flex items-center w-1/6 min-w-[200px] justify-center">
              <img src="./images/playerA.png" alt="playerA" className="w-6 h-6 object-cover" />
              <p className="text-white text-sm ml-2">wooka</p>
            </div>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">3S</p>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">$800.00</p>
            <p className="py-2 px-3  text-white text-center w-1/6 min-w-[200px] self-center">0.32x</p>
            <p className="py-2 px-3  text-green-500 text-center w-1/6 min-w-[200px] self-center">$256.00</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bet;