import { useNavigate } from "react-router-dom";

const LogoSection = ({logowidth, logoheight}) => {
    const navigate = useNavigate();
    return (
        <div style={{ position: "relative", width: `${logowidth}px`, height: `${logoheight}px`}} onClick={()=>navigate('/')} >
            <img src="./images/logomark.png"
                style={{
                    position: "absolute",
                    paddingRight:'49.87px',
                    maxHeight: `${logoheight}px`,
                    objectFit: "cover",
                }}
                alt="logomark"/>
            <img 
                src="./images/logotype.png"
                style={{
                    position: "absolute",
                    padding:'16.65px 0px 15.55px 11.6px',
                    maxWidth: `91.4px`,
                    objectFit: "cover",
                }}
                alt="logotype"/>
        </div>
    )
}

export default LogoSection;