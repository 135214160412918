import { Input, Divider, IconButton } from "@chakra-ui/react";
import { FiUploadCloud } from 'react-icons/fi';
import { useState } from "react";
import LogoSection from "../../components/Logo";

const InputwithLabel = ({ label, placeholder, type }) => {
    return (
        <div className="flex flex-col gap-[6px] md:w-1/2 w-full">
            <label className="text-sm font-[500] text-white text-opacity-50">{label}</label>
            <input className="rounded-lg py-[10px] px-[14px] w-full bg-white bg-opacity-10 text-base font-[400] text-white" placeholder={placeholder} />
        </div>
    );
};

function Profile() {
    const [files, setFiles] = useState([]);
    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
        console.log(event.target.files);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
    };

    return (
        <div className="flex flex-col gap-8 pt-11 pr-4 pb-4 pl-[18px]">
            <div className="md:hidden self-center"><LogoSection logowidth={103} logoheight={60} /></div>
            <p className="text-white font-bold text-2xl">Personal Info</p>
            <div className="bg-gray-900 flex flex-col rounded-lg w-full">
                <div className="flex flex-col p-6 gap-6">
                    <div className="flex flex-col items-center self-stretch gap-5">
                        <img
                            src={!files[0] ? './images/Avatar.png' : URL.createObjectURL(files[0])}
                            alt="avatar"
                            width="126px"
                            height="126px"
                            className="object-cover justify-center"
                        />
                        <div className="flex items-center py-4 px-6 rounded-xl md:w-1/3 w-full">
                            <label htmlFor="dropzone-file" className="inline-flex flex-col items-center justify-center w-full h-40  rounded-lg cursor-pointer bg-white bg-opacity-10">
                                <div className="flex flex-col items-center justify-center gap-3">
                                    <IconButton
                                        py="2"
                                        px="4"
                                        rounded="md"
                                        icon={<FiUploadCloud color="white" />}
                                        bg={"#9E77ED"}
                                        color="white"
                                        onClick={handleUpload}
                                    />
                                    <div className="flex flex-col gap-1 items-center self-stretch">
                                        <div className="flex gap-1">
                                            <p className="text-purple-500 text-xs font-[400]">Click to upload</p>
                                            <p className="text-white text-xs font-[400]">or drag and drop</p>
                                        </div>
                                        <p className="text-white text-xs font-[400]">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>

                                </div>
                                <Input id="dropzone-file" type="file" display="none" accept=".jpg, .png, .jpeg, .gif, .svg, .bmp" onChange={handleFileChange} />
                            </label>
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-6 justify-between w-full">
                        <InputwithLabel label={"First Name"} placeholder={"First Name"} />
                        <InputwithLabel label={"Last Name"} placeholder={"Last Name"} />
                    </div>
                    <div className="flex md:flex-row flex-col gap-6 justify-between w-full">
                        <InputwithLabel label={"Username"} placeholder={"Username"} />
                        <InputwithLabel label={"Email address"} placeholder={"example.com"} />
                    </div>
                </div>
                <Divider className="bg-gray-500" />
                <div className="flex self-end gap-3 md:py-4 md:px-6 py-3 px-4">
                    <button className="bg-white text-gray-700 py-[10px] px-[18px] rounded-lg shadow-md">
                        Cancel
                    </button>
                    <button className="bg-gradient-to-r from-red-500 to-purple-500 hover:to-purple-600 text-white py-[10px] px-[18px] rounded-lg shadow-md">
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Profile;