import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Betting from './pages/Betting';
import Dashboard from './pages/Dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage />}/>
        <Route path='/betting' element={<Betting />}/>
        <Route path='/live' element={<Dashboard index={1}/>}/>
        <Route path='/profile' element={<Dashboard index={2}/>}/>
        <Route path='/tournament' element={<Dashboard index={3}/>}/>
        <Route path='/bet' element={<Dashboard index={4}/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
